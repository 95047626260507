const stripe = require('stripe');

// Get the current mode from environment
const STRIPE_MODE = process.env.STRIPE_MODE || 'test';

// Domain configuration
const DOMAIN = process.env.REACT_APP_CLIENT_URL || 'http://localhost:3000';

// Get the appropriate publishable key based on mode
const getStripePublishableKey = () => {
    return STRIPE_MODE === 'test'
        ? process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY
        : process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY;
};

// Get Stripe instance with appropriate secret key
const getStripeInstance = () => {
    const secretKey = STRIPE_MODE === 'test'
        ? process.env.STRIPE_TEST_SECRET_KEY
        : process.env.STRIPE_LIVE_SECRET_KEY;
    
    if (!secretKey) {
        throw new Error(`Stripe secret key not found for ${STRIPE_MODE} mode`);
    }
    
    return stripe(secretKey);
};

// Subscription price IDs based on mode
const SUBSCRIPTION_PRICES = {
    MONTHLY: STRIPE_MODE === 'test'
        ? process.env.REACT_APP_STRIPE_TEST_PRICE_MONTHLY
        : process.env.REACT_APP_STRIPE_LIVE_PRICE_MONTHLY,
    YEARLY: STRIPE_MODE === 'test'
        ? process.env.REACT_APP_STRIPE_TEST_PRICE_YEARLY
        : process.env.REACT_APP_STRIPE_LIVE_PRICE_YEARLY
};

module.exports = {
    DOMAIN,
    getStripePublishableKey,
    getStripeInstance,
    SUBSCRIPTION_PRICES
}; 