import { loadStripe } from '@stripe/stripe-js';
import supabase from '../supabaseClient';
import { getStripePublishableKey, DOMAIN, SUBSCRIPTION_PRICES } from './stripe-config';

// Initialize Stripe once
let stripePromise;
const getStripe = () => {
    if (!stripePromise) {
        const key = getStripePublishableKey();
        if (!key) {
            throw new Error('Stripe publishable key is not configured');
        }
        console.log('Initializing Stripe with key:', key.substring(0, 8) + '...');
        stripePromise = loadStripe(key);
    }
    return stripePromise;
};

export { SUBSCRIPTION_PRICES };

export const createCheckoutSession = async (priceId) => {
    try {
        // Get API URL from environment
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
        console.log('Using API URL:', apiUrl);
        
        // Get the current user from Supabase
        const { data: { session }, error: userError } = await supabase.auth.getSession();
        
        if (userError || !session?.user) {
            throw new Error('You must be logged in to upgrade.');
        }

        // Get Stripe instance
        const stripe = await getStripe();
        if (!stripe) {
            throw new Error('Failed to initialize Stripe');
        }

        const requestUrl = `${apiUrl}/stripe/create-checkout-session`;
        const requestData = {
            priceId,
            email: session.user.email,
            successUrl: `${DOMAIN}/payment/success?session_id={CHECKOUT_SESSION_ID}`,
            cancelUrl: `${DOMAIN}/pricing`
        };

        console.log('Making request to:', requestUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify(requestData)
        });

        const response = await fetch(requestUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Server response error:', {
                status: response.status,
                statusText: response.statusText,
                data: errorData
            });
            try {
                const jsonError = JSON.parse(errorData);
                throw new Error(jsonError.error || 'Failed to create checkout session');
            } catch (e) {
                throw new Error(`Failed to create checkout session: ${response.status} ${response.statusText}`);
            }
        }

        const { sessionId } = await response.json();
        console.log('Got session ID:', sessionId);

        // Redirect to Stripe checkout
        const { error: redirectError } = await stripe.redirectToCheckout({ sessionId });
        if (redirectError) {
            throw redirectError;
        }
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
}; 