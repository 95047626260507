import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { trackSubscriptionSuccess } from '../lib/analytics';

export default function PaymentSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, supabase } = useAuth();
    const sessionId = new URLSearchParams(location.search).get('session_id');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyAndCreateSubscription = async () => {
            if (!sessionId || !user) return;
            
            setIsLoading(true);
            try {
                // First verify the session with Stripe
                const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/verify-session`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                    body: JSON.stringify({ sessionId })
                });

                const { subscription, error: stripeError } = await response.json();
                
                if (stripeError || !subscription) {
                    throw new Error(stripeError || 'Invalid session');
                }

                // Create subscription record in Supabase
                const { error: dbError } = await supabase
                    .from('subscriptions')
                    .upsert([
                        {
                            user_id: user.id,
                            customer_id: subscription.customer,
                            subscription_id: subscription.id,
                            status: subscription.status,
                            price_id: subscription.items.data[0].price.id,
                            current_period_end: new Date(subscription.current_period_end * 1000),
                            cancel_at_period_end: subscription.cancel_at_period_end
                        }
                    ], {
                        onConflict: 'subscription_id',
                        ignoreDuplicates: false
                    });

                if (dbError) throw dbError;

                // Track successful subscription
                trackSubscriptionSuccess(subscription.id);
                
                // Update user's premium status
                const { error: userError } = await supabase
                    .from('users')
                    .update({ is_premium: true })
                    .eq('id', user.id);

                if (userError) throw userError;

            } catch (error) {
                console.error('Error processing subscription:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        verifyAndCreateSubscription();
    }, [sessionId, user, supabase]);

    // Show loading state
    if (isLoading) {
        return <div className="text-center py-12">
            <h2 className="text-2xl font-bold mb-4">Processing your subscription...</h2>
            {/* Add a loading spinner here */}
        </div>;
    }

    // Show error state
    if (error) {
        return <div className="text-center py-12">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
            <p className="text-gray-600">{error}</p>
            <button 
                onClick={() => navigate('/pricing')}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg"
            >
                Return to Pricing
            </button>
        </div>;
    }

    return (
        <>
            <Helmet>
                <title>Welcome to Premium! - Check-In Questions</title>
                <meta name="description" content="Welcome to your premium membership. Get ready to transform your team meetings!" />
            </Helmet>
            
            <div className="min-h-screen bg-gradient-to-b from-emerald-400 to-green-500 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto space-y-8">
                    {/* Success Message */}
                    <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
                        <div className="text-center">
                            <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
                                <svg className="h-8 w-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            
                            <h1 className="text-4xl font-bold text-gray-900 mb-4">
                                Welcome to Premium! 🎉
                            </h1>
                            
                            <p className="text-xl text-gray-600">
                                You've just unlocked a world of possibilities for creating meaningful team connections. Welcome to the community!
                            </p>
                        </div>
                    </div>

                    {/* What's Next Section */}
                    <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12">
                        <h2 className="text-2xl font-bold mb-6">What's Next?</h2>
                        <div className="grid gap-6 md:grid-cols-2">
                            <div className="space-y-4">
                                <div className="flex items-start">
                                    <span className="text-2xl mr-3">✨</span>
                                    <div>
                                        <h3 className="font-semibold mb-1">Unlimited Questions</h3>
                                        <p className="text-gray-600">Start generating unlimited questions and energizers for your team meetings.</p>
                                    </div>
                                </div>
                                <div className="flex items-start">
                                    <span className="text-2xl mr-3">🎯</span>
                                    <div>
                                        <h3 className="font-semibold mb-1">Advanced Energizers</h3>
                                        <p className="text-gray-600">Access our full library of team energizers and activities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="flex items-start">
                                    <span className="text-2xl mr-3">🌟</span>
                                    <div>
                                        <h3 className="font-semibold mb-1">Monthly Workshops</h3>
                                        <p className="text-gray-600">Watch for our email invitation to the next facilitation workshop.</p>
                                    </div>
                                </div>
                                <div className="flex items-start">
                                    <span className="text-2xl mr-3">🤝</span>
                                    <div>
                                        <h3 className="font-semibold mb-1">Community Access</h3>
                                        <p className="text-gray-600">You'll receive an invitation to join our community of facilitators.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Quick Start Guide */}
                    <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12">
                        <h2 className="text-2xl font-bold mb-6">Quick Start Guide</h2>
                        <ol className="list-decimal list-inside space-y-4 text-gray-600">
                            <li>Head to the <Link to="/" className="text-blue-600 hover:text-blue-700 font-medium">question generator</Link> to start creating engaging questions</li>
                            <li>Check your email for workshop and community invitations</li>
                            <li>Visit your <Link to="/profile" className="text-blue-600 hover:text-blue-700 font-medium">profile</Link> to manage your subscription</li>
                            <li>Explore our <Link to="/how-to" className="text-blue-600 hover:text-blue-700 font-medium">how-to guide</Link> for facilitation tips</li>
                        </ol>
                    </div>

                    {/* Support Section */}
                    <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12">
                        <h2 className="text-2xl font-bold mb-4">Need Help?</h2>
                        <p className="text-gray-600 mb-4">
                            Our team is here to help you make the most of your premium membership. 
                            Contact us at <a href="mailto:support@checkinquestions.com" className="text-blue-600 hover:text-blue-700 font-medium">support@checkinquestions.com</a>
                        </p>
                        <p className="text-sm text-gray-500">
                            You'll be redirected to the homepage in 1 minute, or you can <Link to="/" className="text-blue-600 hover:text-blue-700 font-medium">go there now</Link>.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
} 