import React from 'react';

export default function TermsOfService() {
    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
                <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
                
                <p className="mb-4 text-gray-700">
                    Welcome to Check-in Questions. By accessing or using our website, you agree to be bound by these Terms of Service. Please read them carefully.
                </p>

                <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
                <p className="mb-4 text-gray-700">
                    By accessing or using our services, you agree to these Terms of Service and our Privacy Policy. If you do not agree to these terms, please do not use our services.
                </p>

                <h2 className="text-2xl font-bold mb-4">2. Description of Service</h2>
                <p className="mb-4 text-gray-700">
                    Check-in Questions provides a platform for generating and managing team check-in questions and energizers. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time.
                </p>

                <h2 className="text-2xl font-bold mb-4">3. User Accounts</h2>
                <p className="mb-4 text-gray-700">
                    You may need to create an account to access certain features. You are responsible for maintaining the confidentiality of your account information and for all activities under your account. You agree to provide accurate and complete information when creating an account.
                </p>

                <h2 className="text-2xl font-bold mb-4">4. User Content</h2>
                <p className="mb-4 text-gray-700">
                    Any content you submit, including saved questions and responses, remains your property. However, by submitting content, you grant us a worldwide, non-exclusive license to use, store, and display your content in connection with our services.
                </p>

                <h2 className="text-2xl font-bold mb-4">5. Acceptable Use</h2>
                <p className="mb-4 text-gray-700">
                    You agree not to:
                    <ul className="list-disc ml-6 mt-2">
                        <li>Use our services for any unlawful purpose</li>
                        <li>Attempt to gain unauthorized access to our systems</li>
                        <li>Interfere with or disrupt our services</li>
                        <li>Share inappropriate or harmful content</li>
                    </ul>
                </p>

                <h2 className="text-2xl font-bold mb-4">6. Intellectual Property</h2>
                <p className="mb-4 text-gray-700">
                    Our service, including its original content, features, and functionality, is owned by Check-in Questions and is protected by international copyright, trademark, and other intellectual property laws.
                </p>

                <h2 className="text-2xl font-bold mb-4">7. Limitation of Liability</h2>
                <p className="mb-4 text-gray-700">
                    Check-in Questions is provided "as is" without any warranties. We are not liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
                </p>

                <h2 className="text-2xl font-bold mb-4">8. Changes to Terms</h2>
                <p className="mb-4 text-gray-700">
                    We reserve the right to modify these terms at any time. We will notify users of any material changes by posting the new Terms of Service on this page. Your continued use of our service constitutes acceptance of these changes.
                </p>

                <h2 className="text-2xl font-bold mb-4">9. Governing Law</h2>
                <p className="mb-4 text-gray-700">
                    These terms shall be governed by and construed in accordance with the laws of Denmark, without regard to its conflict of law provisions.
                </p>

                <h2 className="text-2xl font-bold mb-4">Contact</h2>
                <p className="text-gray-700">
                    If you have any questions about these Terms of Service, please contact us at <a href="mailto:support@checkinquestions.com" className="text-blue-500 underline">support@checkinquestions.com</a>.
                </p>
            </div>
        </div>
    );
} 