import { getOpenAIClient } from './openai';

// Cache for storing embeddings
const embeddingsCache = new Map();

// Calculate cosine similarity between two vectors
const cosineSimilarity = (vecA, vecB) => {
  const dotProduct = vecA.reduce((sum, a, i) => sum + a * vecB[i], 0);
  const magnitudeA = Math.sqrt(vecA.reduce((sum, a) => sum + a * a, 0));
  const magnitudeB = Math.sqrt(vecB.reduce((sum, b) => sum + b * b, 0));
  return dotProduct / (magnitudeA * magnitudeB);
};

// Get embedding for a text string
const getEmbedding = async (text) => {
  const cacheKey = text.trim().toLowerCase();
  
  // Check cache first
  if (embeddingsCache.has(cacheKey)) {
    return embeddingsCache.get(cacheKey);
  }

  const openai = getOpenAIClient();
  const response = await openai.embeddings.create({
    model: "text-embedding-3-small",
    input: text,
    dimensions: 384,
  });

  const embedding = response.data[0].embedding;
  embeddingsCache.set(cacheKey, embedding);
  return embedding;
};

// Check similarity between new content and previous questions
export const checkSimilarity = async (newContent, previousQuestions) => {
  try {
    // Get embedding for new content
    const newEmbedding = await getEmbedding(newContent);
    
    // Only compare against last 10 questions
    const recentQuestions = previousQuestions.slice(-10);
    
    let maxSimilarity = 0;
    let mostSimilarQuestion = null;

    // Compare with previous questions
    for (const question of recentQuestions) {
      const questionContent = question.content;
      const questionEmbedding = await getEmbedding(
        typeof questionContent === 'string' && questionContent.startsWith('{')
          ? JSON.parse(questionContent).title + ' ' + JSON.parse(questionContent).description
          : questionContent
      );

      const similarity = cosineSimilarity(newEmbedding, questionEmbedding);
      
      if (similarity > maxSimilarity) {
        maxSimilarity = similarity;
        mostSimilarQuestion = question;
      }
    }

    // Determine if too similar (threshold can be adjusted)
    const isTooSimilar = maxSimilarity > 0.85;

    return {
      isTooSimilar,
      similarityScore: maxSimilarity,
      mostSimilarTo: mostSimilarQuestion?.content || null,
      reason: isTooSimilar 
        ? `Content is too similar to existing question (${Math.round(maxSimilarity * 100)}% similarity)`
        : 'Content is sufficiently unique',
    };
  } catch (error) {
    console.error('Error in similarity check:', error);
    // Fail open - allow content if similarity check fails
    return {
      isTooSimilar: false,
      similarityScore: 0,
      mostSimilarTo: null,
      reason: 'Similarity check failed, proceeding with generation',
    };
  }
}; 