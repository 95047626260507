import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function Support() {
    return (
        <>
            <Helmet>
                <title>Support - Check-In Questions</title>
                <meta name="description" content="Get help and support for Check-In Questions - Contact our support team for assistance with your account, billing, or general inquiries." />
                <meta property="og:title" content="Support - Check-In Questions" />
                <meta property="og:description" content="Get help and support for Check-In Questions - Contact our support team for assistance with your account, billing, or general inquiries." />
            </Helmet>
            <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
                <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
                    <h1 className="text-3xl font-bold mb-6">Support</h1>
                    
                    <p className="mb-8 text-gray-700">
                        Welcome to Check-In Questions support. We're here to help you with any questions or issues you might have about our service.
                    </p>

                    <h2 className="text-2xl font-bold mb-4">Contact Support</h2>
                    <p className="mb-8 text-gray-700">
                        For any support inquiries, please email us at{' '}
                        <a href="mailto:support@checkinquestions.com" className="text-blue-600 hover:text-blue-800 underline">
                            support@checkinquestions.com
                        </a>
                        . We aim to respond to all support requests within 24 hours during business days.
                    </p>

                    <h2 className="text-2xl font-bold mb-4">Billing & Subscription</h2>
                    <p className="mb-8 text-gray-700">
                        For questions about your subscription, billing, or payment issues, please include your account email address and any relevant transaction details in your support request. We process all payments securely through Stripe, our trusted payment provider.
                    </p>

                    <h2 className="text-2xl font-bold mb-4">Account Management</h2>
                    <p className="mb-8 text-gray-700">
                        Need help with your account? We can assist with:
                    </p>
                    <ul className="list-disc pl-6 mb-8 text-gray-700 space-y-2">
                        <li>Account access and login issues</li>
                        <li>Password reset assistance</li>
                        <li>Profile updates</li>
                        <li>Subscription management</li>
                    </ul>

                    <h2 className="text-2xl font-bold mb-4">Response Time</h2>
                    <p className="mb-8 text-gray-700">
                        We strive to provide timely support to all our users:
                    </p>
                    <ul className="list-disc pl-6 mb-8 text-gray-700 space-y-2">
                        <li>Initial response: Within 24 hours (business days)</li>
                        <li>Billing issues: Priority handling</li>
                        <li>Account access issues: Priority handling</li>
                        <li>General inquiries: 1-2 business days</li>
                    </ul>

                    <h2 className="text-2xl font-bold mb-4">Business Hours</h2>
                    <p className="mb-8 text-gray-700">
                        Our support team is available Monday through Friday, 9:00 AM to 5:00 PM Central European Time (CET), excluding major holidays.
                    </p>

                    <div className="bg-blue-50 rounded-xl p-6 mb-8">
                        <h2 className="text-2xl font-bold mb-4">Quick Support</h2>
                        <p className="mb-4 text-gray-700">
                            For fastest support, please include:
                        </p>
                        <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            <li>Your account email address</li>
                            <li>Detailed description of your issue</li>
                            <li>Any relevant error messages</li>
                            <li>Steps to reproduce the issue (if applicable)</li>
                        </ul>
                    </div>

                    <p className="text-gray-700">
                        Thank you for using Check-In Questions. We're committed to providing you with the best possible support experience.
                    </p>
                </div>
            </div>
        </>
    );
} 