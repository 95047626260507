// src/contexts/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import supabase from '../supabaseClient'; // Import the singleton client
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the initial session
    const getSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
      }
      setSession(session);
      setUser(session?.user ?? null);
      setLoading(false);
    };

    getSession();

    // Subscribe to auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
    });

    // Cleanup subscription
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setUser(session?.user ?? null);
      } else if (event === 'SIGNED_OUT') {
        setUser(null);
      } else if (event === 'USER_UPDATED') {
        setUser(session?.user ?? null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const signUp = async ({ email, password, fullName }) => {
    try {
      // Basic validation
      if (!email || !password || !fullName) {
        throw new Error('Please fill in all fields');
      }

      // Attempt signup with metadata - let trigger handle profile creation
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { 
            full_name: fullName,
            subscription_status: 'free',
            daily_questions_remaining: 5,
            questions_reset_date: new Date().toISOString()
          },
          emailRedirectTo: null
        }
      });

      if (error) throw error;

      if (!data?.user) {
        throw new Error('No user data returned');
      }

      return {
        data,
        emailConfirmationRequired: false
      };

    } catch (err) {
      console.error('Signup Process Error:', {
        error: err,
        message: err.message
      });

      let errorMessage = 'Unable to create account. Please try again later.';
      
      if (err.message?.includes('already registered')) {
        errorMessage = 'This email is already registered. Please sign in instead.';
      } else if (err.message?.includes('valid email')) {
        errorMessage = 'Please enter a valid email address.';
      } else if (err.message?.includes('password')) {
        errorMessage = 'Password must be at least 6 characters long.';
      }

      const error = new Error(errorMessage);
      error.originalError = err;
      throw error;
    }
  };

  const signIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      console.error('Error signing in:', error);
      throw error;
    }
    return data;
  };

  const signInWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/auth/v1/callback?next=/profile`,
      },
    });
    
    if (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
    
    return data;
  };

  const signOutUser = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
      throw error;
    }
    navigate('/sign-in'); // Redirect after sign out
  };

  const resetPassword = async (email) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`
      });
      
      if (error) {
        console.error('Error details:', error);
        if (error.message.includes('SMTP')) {
          throw new Error('Email service is not configured. Please contact support.');
        }
        throw error;
      }
    } catch (err) {
      console.error('Full error:', err);
      throw err;
    }
  };

  const verifyEmail = async (token) => {
    try {
      const { error } = await supabase.auth.verifyOtp({
        token_hash: token,
        type: 'email',
      });

      if (error) {
        console.error('Error verifying email:', error);
        throw error;
      }
    } catch (err) {
      console.error('Full error:', err);
      throw err;
    }
  };

  const value = {
    signUp,
    signIn,
    signInWithGoogle,
    signOut: signOutUser,
    resetPassword,
    verifyEmail,
    user,
    session,
    supabase,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);