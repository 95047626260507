import React, { useState, useEffect } from 'react';
import { ThumbsUp, ThumbsDown, Star } from '@phosphor-icons/react';
import { useAuth } from '../contexts/AuthContext';

export default function QuestionActions({ question, onVote }) {
  const { supabase, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [savedStatus, setSavedStatus] = useState(false);
  const [helpfulStatus, setHelpfulStatus] = useState(null);

  useEffect(() => {
    const checkStatus = async () => {
      if (user && question?.id) {
        await checkVoteStatus();
        await checkSavedStatus();
      }
    };
    checkStatus();
  }, [user, question]);

  const checkVoteStatus = async () => {
    if (!user?.id || !question?.id) return;
    
    try {
      const { data, error } = await supabase
        .from('votes_questions')
        .select('vote_type')
        .eq('question_id', question.id)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error checking vote status:', error);
        return;
      }

      if (data && data.length > 0) {
        setHelpfulStatus(data[0].vote_type);
      } else {
        setHelpfulStatus(null);
      }
    } catch (error) {
      console.error('Error checking vote status:', error);
    }
  };

  const checkSavedStatus = async () => {
    if (!user?.id || !question?.id) return;
    
    try {
      const { data, error } = await supabase
        .from('saved_questions')
        .select('id')
        .eq('question_id', question.id)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error checking saved status:', error);
        return;
      }

      setSavedStatus(data && data.length > 0);
    } catch (error) {
      console.error('Error checking saved status:', error);
    }
  };

  const handleVote = async (voteType) => {
    if (!user || !question?.id) return;
    
    setIsLoading(true);
    try {
      // Delete existing vote if any
      await supabase
        .from('votes_questions')
        .delete()
        .eq('question_id', question.id)
        .eq('user_id', user.id);

      // Insert new vote if not removing
      if (voteType !== helpfulStatus) {
        const { error: voteError } = await supabase
          .from('votes_questions')
          .insert([{
            question_id: question.id,
            user_id: user.id,
            vote_type: voteType
          }]);

        if (voteError) throw voteError;
      }

      // Update local state
      setHelpfulStatus(voteType === helpfulStatus ? null : voteType);
      
      // Trigger refresh of parent component
      if (onVote) onVote();
      
    } catch (error) {
      console.error('Error voting:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!user) return;
    setIsLoading(true);
    
    try {
      if (!savedStatus) {
        // Save question
        if (question.id) {
          const { error } = await supabase
            .from('saved_questions')
            .insert({
              question_id: question.id,
              user_id: user.id,
              created_at: new Date().toISOString()
            });

          if (error) throw error;
        }
        setSavedStatus(true);
      } else {
        // Unsave question
        if (question.id) {
          const { error } = await supabase
            .from('saved_questions')
            .delete()
            .eq('question_id', question.id)
            .eq('user_id', user.id);

          if (error) throw error;
        }
        setSavedStatus(false);
      }
    } catch (error) {
      console.error('Error saving/unsaving question:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = isLoading || !user || !question;

  return (
    <div className="flex flex-col md:flex-row gap-2 w-full pt-4">
      <button
        type="button"
        onClick={() => handleVote('helpful')}
        disabled={isDisabled}
        className={`flex-1 px-4 py-2 flex items-center justify-center gap-2 rounded-lg transition-colors ${
          helpfulStatus === 'helpful' 
            ? 'bg-green-500 text-white hover:bg-green-600' 
            : 'bg-gray-200 hover:bg-gray-300'
        } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <ThumbsUp weight={helpfulStatus === 'helpful' ? 'fill' : 'regular'} />
        {helpfulStatus === 'helpful' ? 'Helpful!' : 'Helpful'}
      </button>
      
      <button
        type="button"
        onClick={() => handleVote('not_helpful')}
        disabled={isDisabled}
        className={`flex-1 px-4 py-2 flex items-center justify-center gap-2 rounded-lg transition-colors ${
          helpfulStatus === 'not_helpful' 
            ? 'bg-red-500 text-white hover:bg-red-600' 
            : 'bg-gray-200 hover:bg-gray-300'
        } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <ThumbsDown weight={helpfulStatus === 'not_helpful' ? 'fill' : 'regular'} />
        {helpfulStatus === 'not_helpful' ? 'Not Helpful!' : 'Not Helpful'}
      </button>

      <button
        type="button"
        onClick={handleSave}
        disabled={isDisabled}
        className={`flex-1 px-4 py-2 flex items-center justify-center gap-2 rounded-lg transition-colors ${
          savedStatus 
            ? 'bg-yellow-500 text-white hover:bg-yellow-600' 
            : 'bg-gray-200 hover:bg-gray-300'
        } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <Star weight={savedStatus ? 'fill' : 'regular'} />
        {savedStatus ? 'Saved!' : 'Save'}
      </button>
    </div>
  );
}