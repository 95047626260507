// src/lib/openai.js

import OpenAI from 'openai';

// Define constants first
const QUESTION_STRUCTURES = [
  { type: 'reflection', pattern: 'What [insight/lesson/discovery] have you [experienced/learned] recently about [topic]?' },
  { type: 'hypothetical', pattern: 'If you could [action] [timeframe], what would you [do/choose] and why?' },
  { type: 'storytelling', pattern: 'Tell us about a moment when [situation] led to [outcome].' },
  { type: 'metaphorical', pattern: 'If your [current state/feeling/experience] were a [metaphor], what would it be?' },
  { type: 'growth', pattern: 'How has [experience/challenge] shaped your perspective on [topic]?' },
  { type: 'appreciation', pattern: 'What [person/experience/thing] has recently brought unexpected [positive outcome]?' },
  { type: 'future', pattern: 'What [goal/change/possibility] are you most excited about [timeframe]?' },
  { type: 'creativity', pattern: 'How would you describe [topic/feeling/experience] using [creative medium]?' },
  { type: 'connection', pattern: 'What [shared experience/value/interest] has helped you connect with others recently?' },
  { type: 'curiosity', pattern: 'What unexpected [discovery/observation/connection] has sparked your interest lately?' }
];

const TOPIC_CATEGORIES = {
  'personal_growth': {
    themes: ['learning', 'goals', 'challenges', 'achievements', 'skills'],
    contexts: ['work', 'personal life', 'relationships', 'hobbies', 'creativity']
  },
  'team_dynamics': {
    themes: ['collaboration', 'communication', 'trust', 'support', 'feedback'],
    contexts: ['projects', 'meetings', 'decisions', 'celebrations', 'challenges']
  },
  'emotional_intelligence': {
    themes: ['self-awareness', 'empathy', 'resilience', 'motivation', 'adaptability'],
    contexts: ['interactions', 'decisions', 'responses', 'understanding', 'growth']
  },
  'innovation': {
    themes: ['creativity', 'problem-solving', 'ideas', 'experimentation', 'improvement'],
    contexts: ['work processes', 'solutions', 'approaches', 'methods', 'tools']
  },
  'wellbeing': {
    themes: ['balance', 'health', 'energy', 'mindfulness', 'satisfaction'],
    contexts: ['work-life', 'stress management', 'habits', 'activities', 'environment']
  }
};

// Define energizer activity templates for variety
const ENERGIZER_TEMPLATES = [
  {
    category: 'storytelling',
    patterns: [
      'Share personal stories around a theme',
      'Create collaborative stories',
      'Use metaphors to describe experiences',
      'Share moments of learning or growth'
    ]
  },
  {
    category: 'movement',
    patterns: [
      'Physical team formations',
      'Gesture-based activities',
      'Space utilization exercises',
      'Rhythm and movement games'
    ]
  },
  {
    category: 'creative_expression',
    patterns: [
      'Visual art activities',
      'Music or sound exercises',
      'Word association games',
      'Role-playing scenarios'
    ]
  },
  {
    category: 'problem_solving',
    patterns: [
      'Group puzzles',
      'Strategy challenges',
      'Collaborative building',
      'Pattern recognition games'
    ]
  },
  {
    category: 'reflection',
    patterns: [
      'Guided visualization',
      'Mindfulness exercises',
      'Appreciation circles',
      'Perspective sharing'
    ]
  },
  {
    category: 'team_building',
    patterns: [
      'Trust exercises',
      'Communication games',
      'Cooperation challenges',
      'Team coordination activities'
    ]
  },
  {
    category: 'icebreakers',
    patterns: [
      'Two truths and a lie variations',
      'Speed meeting formats',
      'Common ground discovery',
      'Unique fact sharing'
    ]
  },
  {
    category: 'brainstorming',
    patterns: [
      'Idea chain reactions',
      'Random word association',
      'Visual ideation',
      'Reverse thinking exercises'
    ]
  }
];

let openaiInstance = null;

// OpenAI client initialization
export const getOpenAIClient = () => {
  if (!openaiInstance) {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      throw new Error('OpenAI API key is not configured');
    }
    openaiInstance = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true,
    });
  }
  return openaiInstance;
};

// Get least used topics and structures
const getVarietyElements = (previousQuestions = []) => {
  try {
    const topicUsage = {};
    const structureUsage = {};
    
    // Initialize usage counters
    Object.keys(TOPIC_CATEGORIES).forEach(topic => topicUsage[topic] = 0);
    QUESTION_STRUCTURES.forEach(structure => structureUsage[structure.type] = 0);
    
    // Count usage in previous questions
    previousQuestions.forEach(q => {
      if (q && q.content) {
        Object.entries(TOPIC_CATEGORIES).forEach(([topic, { themes }]) => {
          if (themes.some(theme => q.content.toLowerCase().includes(theme))) {
            topicUsage[topic]++;
          }
        });
        
        QUESTION_STRUCTURES.forEach(structure => {
          if (q.content.toLowerCase().includes(structure.pattern.split('[')[0].toLowerCase())) {
            structureUsage[structure.type]++;
          }
        });
      }
    });
    
    // Get least used elements
    const leastUsedTopics = Object.entries(topicUsage)
      .sort(([,a], [,b]) => a - b)
      .slice(0, 3)
      .map(([topic]) => topic);
      
    const leastUsedStructures = Object.entries(structureUsage)
      .sort(([,a], [,b]) => a - b)
      .slice(0, 3)
      .map(([type]) => QUESTION_STRUCTURES.find(s => s.type === type))
      .filter(Boolean);
      
    return {
      leastUsedTopics: leastUsedTopics.length > 0 ? leastUsedTopics : Object.keys(TOPIC_CATEGORIES).slice(0, 3),
      leastUsedStructures: leastUsedStructures.length > 0 ? leastUsedStructures : QUESTION_STRUCTURES.slice(0, 3)
    };
  } catch (error) {
    console.error('Error in getVarietyElements:', error);
    return {
      leastUsedTopics: Object.keys(TOPIC_CATEGORIES).slice(0, 3),
      leastUsedStructures: QUESTION_STRUCTURES.slice(0, 3)
    };
  }
};

// Get system prompt based on type
const getSystemPrompt = (type, variety) => {
  if (type === 'energizer') {
    return `You are an expert facilitator creating unique team exercises. Each response should be completely different from previous ones. Focus on:
1. Novel and engaging activities
2. Clear, practical instructions
3. Inclusive experiences
4. Meaningful connections
5. Psychological safety

Consider these themes for variety: ${variety.leastUsedTopics.join(', ')}
Important: Never repeat or reuse previous responses. Each exercise must be unique.`;
  }
  
  return `You are an expert in crafting unique, thought-provoking questions. Each question must be completely different from previous ones. Focus on:
1. Novel perspectives
2. Clear, concise wording
3. Open-ended responses
4. Inclusive language
5. Engaging topics

Use these question structures for variety:
${variety.leastUsedStructures.map(s => s.pattern).join('\n')}

Consider these themes: ${variety.leastUsedTopics.map(topic => 
  TOPIC_CATEGORIES[topic].themes.join(', ')
).join(' | ')}

Important: Never repeat or reuse previous questions. Each question must be unique.`;
};

// Get user prompt based on parameters
const getUserPrompt = (type, mood, complexity, previousQuestions = []) => {
  if (type === 'energizer') {
    // Select a random template category that hasn't been used recently
    const usedCategories = new Set(
      previousQuestions
        .slice(-5)
        .map(q => {
          try {
            const content = typeof q.content === 'string' ? JSON.parse(q.content) : q.content;
            return content.category;
          } catch {
            return null;
          }
        })
        .filter(Boolean)
    );

    const availableTemplates = ENERGIZER_TEMPLATES.filter(t => !usedCategories.has(t.category));
    const template = availableTemplates.length > 0 
      ? availableTemplates[Math.floor(Math.random() * availableTemplates.length)]
      : ENERGIZER_TEMPLATES[Math.floor(Math.random() * ENERGIZER_TEMPLATES.length)];

    const pattern = template.patterns[Math.floor(Math.random() * template.patterns.length)];

    return `Create a ${complexity} group energizer with ${mood} tone, inspired by this pattern: "${pattern}"

Return it in this exact JSON format:
{
  "title": "Unique and creative title",
  "emoji": "Relevant emoji",
  "category": "${template.category}",
  "description": "Brief, engaging description",
  "timeFrame": "Duration (e.g., 5-15 minutes)",
  "groupSize": "Participant range (e.g., 4-12 people)",
  "materials": "Required materials, or 'None required'",
  "purpose": "Clear goal and benefits",
  "steps": [
    "Clear step-by-step instructions",
    "Each step should be actionable",
    "Include setup and wrap-up"
  ],
  "facilitatorNotes": [
    "Important tips for facilitation",
    "Guidance for inclusive participation",
    "Adaptation suggestions"
  ]
}

Requirements:
1. Must be completely different from previous energizers
2. Should be engaging and interactive
3. Focus on building psychological safety
4. Include clear instructions
5. Be adaptable for different group sizes
6. Consider both in-person and remote settings`;
  }

  const variety = getVarietyElements(previousQuestions);
  const suggestedStructure = variety.leastUsedStructures[Math.floor(Math.random() * variety.leastUsedStructures.length)];
  const suggestedTopic = variety.leastUsedTopics[Math.floor(Math.random() * variety.leastUsedTopics.length)];

  return `Create a unique ${complexity} ${type} question with ${mood} tone using this structure as inspiration: "${suggestedStructure.pattern}"

Consider exploring themes from: ${TOPIC_CATEGORIES[suggestedTopic].themes.join(', ')}
Context suggestions: ${TOPIC_CATEGORIES[suggestedTopic].contexts.join(', ')}

Requirements:
- Must be different from: ${previousQuestions.slice(-3).map(q => q.content).join('; ')}
- Under 20 words
- Clear and direct
- Single theme
- Open-ended
- Engaging and thought-provoking`;
};

// Check similarity between contents
const checkSimilarity = async (newContent, previousQuestions) => {
  // For now, implement a simple text-based similarity check
  const isTooSimilar = previousQuestions.some(q => {
    const content = q.content || '';
    const similarity = content.toLowerCase().includes(newContent.toLowerCase()) ||
                      newContent.toLowerCase().includes(content.toLowerCase());
    return similarity;
  });

  return {
    isTooSimilar,
    similarityScore: isTooSimilar ? 1 : 0,
    mostSimilarTo: null,
    reason: isTooSimilar ? 'Content is too similar to existing question' : 'Content is sufficiently unique'
  };
};

// Main question generation function
export const generateQuestion = async ({
  selectedType,
  selectedMood,
  selectedComplexity,
  previousQuestions = [],
}) => {
  const openai = getOpenAIClient();
  
  const model = (selectedComplexity === 'deep' || selectedType === 'energizer') 
    ? 'gpt-4-turbo-preview' 
    : 'gpt-3.5-turbo';

  const variety = getVarietyElements(previousQuestions);
  const systemPrompt = getSystemPrompt(selectedType, variety);
  const userPrompt = getUserPrompt(
    selectedType,
    selectedMood,
    selectedComplexity,
    previousQuestions
  );

  try {
    const completion = await openai.chat.completions.create({
      model,
      messages: [
        { 
          role: "system", 
          content: selectedType === 'energizer' 
            ? `You are an expert facilitator. You MUST return ONLY valid JSON with no additional text or formatting. Consider these themes for variety: ${variety.leastUsedTopics.join(', ')}` 
            : systemPrompt 
        },
        { role: "user", content: userPrompt }
      ],
      temperature: selectedType === 'energizer' ? 0.7 : 1.0,
      max_tokens: selectedType === 'energizer' ? 500 : 100,
      presence_penalty: 1.0,
      frequency_penalty: 1.0,
    });

    let generatedContent;
    
    if (selectedType === 'energizer') {
      try {
        const completion = await openai.chat.completions.create({
          model,
          messages: [
            { 
              role: "system", 
              content: `You are an expert facilitator. Return ONLY valid JSON with no markdown. Format:
{
  "title": "Brief title",
  "emoji": "🎯",
  "description": "One sentence description",
  "timeFrame": "5-10 minutes",
  "groupSize": "4-12 people",
  "materials": "None required",
  "purpose": "One clear goal",
  "steps": ["Step 1", "Step 2", "Step 3"],
  "facilitatorNotes": ["Note 1", "Note 2"]
}`
            },
            { role: "user", content: userPrompt }
          ],
          temperature: 0.5,
          max_tokens: 800,
          presence_penalty: 0.3,
          frequency_penalty: 0.3,
        });

        const response = completion.choices[0].message.content.trim();
        console.log('Raw response:', response);
        
        // Basic cleanup
        let cleanJson = response
          .replace(/```json\n?|\n?```/g, '')
          .replace(/\n\s*/g, ' ')
          .replace(/\s+/g, ' ')
          .replace(/"\s*:\s*/g, '":')
          .replace(/,\s*]/g, ']')
          .trim();
        
        console.log('Cleaned JSON:', cleanJson);
        
        try {
          generatedContent = JSON.parse(cleanJson);
        } catch (parseError) {
          console.error('Parse error:', parseError);
          throw new Error('Failed to parse energizer format');
        }

        // Validate and normalize the content structure
        const requiredFields = ['title', 'emoji', 'description', 'timeFrame', 'groupSize', 'materials', 'purpose', 'steps', 'facilitatorNotes'];
        const missingFields = requiredFields.filter(field => !generatedContent[field]);
        
        if (missingFields.length > 0) {
          throw new Error(`Missing fields: ${missingFields.join(', ')}`);
        }
        
        // Ensure arrays are properly formatted
        ['steps', 'facilitatorNotes'].forEach(field => {
          if (!Array.isArray(generatedContent[field])) {
            generatedContent[field] = [generatedContent[field]];
          }
          
          // Clean up array items and ensure minimum length
          generatedContent[field] = generatedContent[field]
            .map(item => typeof item === 'string' ? item.trim() : '')
            .filter(item => item.length > 0);
            
          if (generatedContent[field].length < 2) {
            throw new Error(`Insufficient ${field}`);
          }
        });
        
        // Add or validate category
        if (!generatedContent.category) {
          const content = `${generatedContent.title} ${generatedContent.description}`.toLowerCase();
          const bestMatch = ENERGIZER_TEMPLATES.find(t => 
            t.patterns.some(p => content.includes(p.toLowerCase()))
          ) || ENERGIZER_TEMPLATES[0];
          generatedContent.category = bestMatch.category;
        }
        
        // If we reach here, we have valid content
        return generatedContent;
      } catch (error) {
        console.error('Generation Error:', error);
        throw new Error('Failed to generate valid energizer format. Please try again.');
      }
    } else {
      generatedContent = completion.choices[0].message.content;
    }

    const similarityResult = await checkSimilarity(
      selectedType === 'energizer' 
        ? `${generatedContent.title} ${generatedContent.description}`
        : generatedContent,
      previousQuestions
    );

    if (similarityResult.isTooSimilar) {
      return generateQuestion({
        selectedType,
        selectedMood,
        selectedComplexity,
        previousQuestions,
      });
    }

    return generatedContent;
  } catch (error) {
    console.error('Generation Error:', error);
    throw new Error(error.message || 'Failed to generate content. Please try again.');
  }
};