import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

const SubscriptionContext = createContext();

export function useSubscriptionStatus() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const { user, supabase } = useAuth();
  const [subscription, setSubscription] = useState({
    status: 'free',
    isActive: false,
    interval: null,
    currentPeriodEnd: null
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    
    async function fetchSubscription() {
      if (!user) {
        if (isMounted) {
          setSubscription({
            status: 'free',
            isActive: false,
            interval: null,
            currentPeriodEnd: null
          });
          setLoading(false);
        }
        return;
      }

      try {
        // Simple subscription check without prices relationship
        const { data: subscriptionData, error: subscriptionError } = await supabase
          .from('subscriptions')
          .select('id, status, current_period_end')
          .eq('user_id', user.id)
          .maybeSingle();

        // Log the response for debugging
        console.log('Subscription response:', { subscriptionData, subscriptionError });

        // Handle case where no subscription is found
        if (!subscriptionData || subscriptionError) {
          console.log('No active subscription found or error occurred');
          if (isMounted) {
            setSubscription({
              status: 'free',
              isActive: false,
              interval: null,
              currentPeriodEnd: null
            });
          }
        } else {
          // Valid subscription data found
          if (isMounted) {
            setSubscription({
              status: subscriptionData.status || 'free',
              isActive: subscriptionData.status === 'active',
              interval: null,
              currentPeriodEnd: subscriptionData.current_period_end || null
            });
          }
        }
      } catch (error) {
        console.error('Subscription check failed:', error);
        if (isMounted) {
          setSubscription({
            status: 'free',
            isActive: false,
            interval: null,
            currentPeriodEnd: null
          });
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchSubscription();
    
    return () => {
      isMounted = false;
    };
  }, [user, supabase]);

  const value = {
    subscription,
    loading,
    isSubscribed: subscription.status === 'active',
    isPremium: subscription.status === 'active',
    isFree: subscription.status !== 'active'
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
} 