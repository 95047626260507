// src/App.js

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import Navigation from './components/Navigation';
import AppRoutes from './components/AppRoutes';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <SubscriptionProvider>
          <HelmetProvider>
            <div className="min-h-screen flex flex-col bg-gradient-to-t from-sky-400 to-blue-500">
              <Navigation />
              <AppRoutes />
              <Footer />
              <CookieConsent />
            </div>
          </HelmetProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;