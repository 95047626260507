import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { trackNavigation } from '../lib/analytics';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import UserProfile from './UserProfile';
import CheckInGenerator from './CheckInGenerator';
import HowTo from './HowTo';
import About from './About';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Support from './Support';
import Pricing from './Pricing';
import PaymentSuccess from './PaymentSuccess';
import VerifyEmail from '../pages/VerifyEmail';

// Component to handle auth callback
function AuthCallback() {
  const navigate = useNavigate();
  const { supabase } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const { error } = await supabase.auth.getSession();
        if (error) throw error;

        // Get the next parameter from the URL
        const params = new URLSearchParams(window.location.search);
        const next = params.get('next') || '/profile';
        
        navigate(next);
      } catch (err) {
        console.error('Error handling auth callback:', err);
        navigate('/sign-in');
      }
    };

    handleCallback();
  }, [navigate, supabase.auth]);

  return <div>Processing authentication...</div>;
}

// Component to protect routes that require authentication
function RequireAuth({ children }) {
  const location = useLocation();
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location.pathname }} replace />;
  }

  return children;
}

export default function AppRoutes() {
  const location = useLocation();

  // Track page views
  useEffect(() => {
    const currentPath = location.pathname;
    trackNavigation(document.referrer, currentPath);
  }, [location]);

  return (
    <main className="flex-grow">
      <Routes>
        {/* Public Routes */}
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/how-to" element={<HowTo />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/support" element={<Support />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />

        {/* Protected Routes */}
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <UserProfile />
            </RequireAuth>
          }
        />

        {/* Home Route - Accessible Without Authentication */}
        <Route path="/" element={<CheckInGenerator />} />

        {/* Redirect for Undefined Routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </main>
  );
} 