import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CookieConsent() {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookieConsent', true);
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 flex flex-col md:flex-row justify-between items-center">
            <p className="mb-2 md:mb-0">
                🍪 We use cookies to improve your experience and save your preferences. Learn more in our{' '}
                <Link to="/privacy-policy" className="underline text-blue-400 hover:text-blue-500">
                    Privacy Policy
                </Link>
            </p>
            <button 
                onClick={acceptCookies}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
                Accept
            </button>
        </div>
    );
} 