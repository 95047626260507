import React from 'react';
export default function PrivacyPolicy() {
    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
                
                <p className="mb-4 text-gray-700">
                    Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services, such as logging in and creating profiles.
                </p>

                <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
                <p className="mb-4 text-gray-700">
                    We may collect personal information that you voluntarily provide to us, such as your name, email address, and any other information you choose to include when creating a profile or contacting us.
                </p>

                <h2 className="text-2xl font-bold mb-4">Use of Information</h2>
                <p className="mb-4 text-gray-700">
                    We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience on our website.
                </p>
                    
                <h2 className="text-2xl font-bold mb-4">Cookies</h2>
                <p className="mb-4 text-gray-700">
                    We use cookies to enhance your experience on our website. Cookies are small data files that are placed on your device and help us understand how you interact with our services. You can choose to disable cookies through your browser settings, but this may limit your ability to use some features of the website.
                </p>

                <h2 className="text-2xl font-bold mb-4">Third-Party Services</h2>
                <p className="mb-4 text-gray-700">
                    We may use third-party services, such as analytics tools and authentication services, to enhance functionality. These third-party providers may collect and process your data as described in their respective privacy policies.
                </p>

                <h2 className="text-2xl font-bold mb-4">Data Security</h2>
                <p className="mb-4 text-gray-700">
                    We strive to use commercially acceptable means to protect your personal information. However, please note that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>

                <h2 className="text-2xl font-bold mb-4">Changes to This Privacy Policy</h2>
                <p className="mb-4 text-gray-700">
                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post any changes on this page, and the updated policy will take effect as soon as it is posted.
                </p>

                <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                <p className="text-gray-700">
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@checkinquestions.com" className="text-blue-500 underline">support@checkinquestions.com</a>.
                </p>
            </div>
        </div>
    );
} 