import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { trackSignUp, trackError } from '../lib/analytics';
import GoogleSignInButton from './GoogleSignInButton';

export default function SignUp() {
    const { signUp } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [verificationPending, setVerificationPending] = useState(false);
    const [verificationEmail, setVerificationEmail] = useState("");
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);
        
        try {
            const { data, emailConfirmationRequired } = await signUp({
                email,
                password,
                fullName
            });

            if (emailConfirmationRequired) {
                trackSignUp('email');
                setVerificationEmail(email);
                setVerificationPending(true);
                setEmail("");
                setPassword("");
                setFullName("");
            } else {
                navigate('/profile');
            }
        } catch (err) {
            console.error('Signup error:', err);
            let errorMessage = 'Failed to create account.';
            
            if (err.message?.includes('Email rate limit exceeded')) {
                errorMessage = 'Too many signup attempts. Please try again later.';
            } else if (err.message?.includes('already registered')) {
                errorMessage = 'This email is already registered. Please sign in instead.';
            } else if (err.message?.includes('valid email')) {
                errorMessage = 'Please enter a valid email address.';
            }
            
            setError(errorMessage);
            trackError('signup_error', { error: errorMessage });
        } finally {
            setLoading(false);
        }
    };

    if (verificationPending) {
        return (
            <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
                <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4 text-center">
                    <div className="mb-6">
                        <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                            <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                            </svg>
                        </div>
                    </div>
                    <h2 className="text-3xl font-bold mb-4">Check Your Email</h2>
                    <div className="space-y-4 text-gray-600">
                        <p className="text-lg">
                            We've sent a verification link to <span className="font-medium text-gray-900">{verificationEmail}</span>
                        </p>
                        <p>
                            Click the link in the email to verify your account and start using Check-In Questions.
                        </p>
                        <div className="bg-gray-50 p-4 rounded-lg mt-6">
                            <h3 className="font-medium text-gray-900 mb-2">Didn't receive the email?</h3>
                            <ul className="text-sm space-y-2">
                                <li>• Check your spam folder</li>
                                <li>• Make sure you entered the correct email address</li>
                                <li>• Allow a few minutes for the email to arrive</li>
                            </ul>
                        </div>
                        <div className="mt-8">
                            <button
                                onClick={() => navigate('/sign-in')}
                                className="text-blue-600 hover:text-blue-800 font-medium"
                            >
                                Return to Sign In
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold mb-4">Create Your Free Account</h2>
                    <p className="text-lg text-gray-600 mb-4">
                        Get started with 5 free questions per day and unlock the power of meaningful team interactions.
                    </p>
                    <div className="inline-flex items-center space-x-2 text-sm text-gray-500">
                        <span>Looking for more?</span>
                        <Link to="/pricing" className="text-blue-600 hover:text-blue-700 font-medium">
                            View our Premium features →
                        </Link>
                    </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-6 mb-8">
                    <h3 className="font-semibold mb-4">What you'll get:</h3>
                    <ul className="space-y-3">
                        <li className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            5 free questions every day
                        </li>
                        <li className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            Save your favorite questions
                        </li>
                        <li className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            Access to basic question types
                        </li>
                    </ul>
                </div>

                <form onSubmit={handleSignUp} className="space-y-4">
                    <div>
                        <label htmlFor="fullName" className="sr-only">Full Name</label>
                        <input
                            id="fullName"
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Full Name"
                            required
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                        />
                    </div>
                    
                    <div>
                        <label htmlFor="email" className="sr-only">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                        />
                    </div>
                    
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full p-2 bg-black text-white rounded hover:bg-gray-800 disabled:opacity-50"
                    >
                        {loading ? "Creating Account..." : "Create Free Account"}
                    </button>

                    <div className="relative my-6">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                    </div>

                    <GoogleSignInButton onError={setError} />
                </form>

                {error && (
                    <p className="mt-2 text-sm text-red-500">{error}</p>
                )}

                <p className="mt-4 text-center text-sm text-blue-500">
                    <Link to="/sign-in">
                        Already have an account? Sign in
                    </Link>
                </p>
            </div>
        </div>
    );
}