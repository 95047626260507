import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createCheckoutSession } from '../lib/stripe';
import { SUBSCRIPTION_PRICES } from '../lib/stripe-config';
import { trackSubscriptionAttempt } from '../lib/analytics';

export default function Pricing() {
    const [isAnnual, setIsAnnual] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleSubscribe = async (priceId) => {
        if (!user) {
            navigate('/sign-in', { state: { from: '/pricing' } });
            return;
        }

        setError(null);
        setLoading(true);
        try {
            console.log('Starting subscription process for price:', priceId);
            trackSubscriptionAttempt(isAnnual ? 'yearly' : 'monthly');
            await createCheckoutSession(priceId);
        } catch (error) {
            console.error('Subscription error:', error);
            setError(error.message || 'Failed to start checkout process. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const plans = [
        {
            name: 'Free',
            price: '0',
            description: 'Perfect for trying out Check-in Questions and exploring how AI-powered questions can enhance your team meetings and workshops.',
            features: [
                '5 free questions per day',
                'Basic question types',
                'Save questions',
                'Community support'
            ],
            priceId: null,
            buttonText: user ? 'Current Plan' : 'Sign Up Free'
        },
        {
            name: 'Premium',
            price: isAnnual ? '50' : '5',
            period: isAnnual ? '/year' : '/month',
            description: 'Perfect for team leaders, facilitators, and managers who want to create more meaningful team connections.',
            features: [
                '✨ Unlimited access to our AI-powered question generator',
                '🎯 Advanced energizer activities and team exercises',
                '🌟 Monthly online workshops on facilitation best practices',
                '🤝 Join our global community of facilitators and team leaders',
                'Create more meaningful team connections, learn new facilitation techniques, and share experiences with peers globally while staying updated with the latest team engagement practices. Includes access to monthly virtual workshops where experienced facilitators share insights, techniques, and real-world examples of successful team building strategies. Join a thriving community of professionals dedicated to creating better team experiences and fostering psychological safety in their organizations.'
            ],
            priceId: isAnnual ? SUBSCRIPTION_PRICES.YEARLY : SUBSCRIPTION_PRICES.MONTHLY,
            buttonText: 'Get Premium'
        }
    ];

    const faqs = [
        {
            question: "What's included in the free plan?",
            answer: "The free plan includes 5 question generations per day, access to basic question types, and the ability to save your favorite questions."
        },
        {
            question: "Can I switch between monthly and annual billing?",
            answer: "Yes, you can switch between billing periods at any time. If you switch to annual billing, you'll save more than 15% compared to monthly billing."
        },
        {
            question: "How do the monthly workshops work?",
            answer: "Premium members get access to live online workshops led by experienced facilitators, covering topics like team engagement, psychological safety, and facilitation techniques."
        },
        {
            question: "What happens if I need more than 5 questions per day?",
            answer: "You can upgrade to our Premium plan at any time to get unlimited question generations and access to all features."
        }
    ];

    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            {error && (
                <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-xl text-red-600">
                    {error}
                </div>
            )}
            
            {/* Main Section - Header, Billing Toggle, and Pricing Cards */}
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">

                {/* Header */}
                <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
                    Simple, transparent pricing
                </h1>
                <p className="text-xl text-gray-600 text-center mb-8">
                    Start with 5 free questions per day. Upgrade when you're ready.
                </p>

                {/* Billing Toggle */}
                <div className="bg-gray-100 rounded-lg p-0.5 flex justify-center max-w-sm mx-auto mb-12">
                    <button
                        type="button"
                        className={`relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8 ${
                            !isAnnual ? 'bg-white border-gray-200 shadow-sm text-gray-900' : 'text-gray-700'
                        }`}
                        onClick={() => setIsAnnual(false)}
                    >
                        Monthly billing
                    </button>
                    <button
                        type="button"
                        className={`relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8 ${
                            isAnnual ? 'bg-white border-gray-200 shadow-sm text-gray-900' : 'text-gray-700'
                        }`}
                        onClick={() => setIsAnnual(true)}
                    >
                        Annual billing
                        <span className="absolute -top-3 right-0 bg-green-500 text-white text-xs px-2 py-0.5 rounded-full">
                            Save 17%
                        </span>
                    </button>
                </div>

                {/* Pricing Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {plans.map((plan) => (
                        <div
                            key={plan.name}
                            className={`bg-gray-50 rounded-2xl overflow-hidden ${
                                plan.name === 'Premium' ? 'border-2 border-blue-500' : 'border border-gray-200'
                            }`}
                        >
                            <div className="p-8 flex flex-col min-h-[700px]">
                                {/* Header section - fixed height */}
                                <div className="min-h-[140px]">
                                    <h2 className="text-2xl font-semibold text-gray-900 flex items-center">
                                        {plan.name}
                                        {plan.name === 'Premium' && (
                                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                Most Popular
                                            </span>
                                        )}
                                    </h2>
                                    <p className="mt-4 text-gray-500">{plan.description}</p>
                                </div>

                                {/* Price and button section - fixed position */}
                                <div className="my-8">
                                    <p className="flex items-baseline">
                                        <span className="text-4xl font-extrabold text-gray-900">${plan.price}</span>
                                        {plan.period && <span className="text-base font-medium text-gray-500">{plan.period}</span>}
                                    </p>
                                    <button
                                        onClick={() => {
                                            if (plan.name === 'Premium') {
                                                handleSubscribe(plan.priceId);
                                            } else {
                                                navigate('/sign-up');
                                            }
                                        }}
                                        disabled={loading || (user && plan.name === 'Free')}
                                        className={`mt-8 block w-full rounded-xl py-3 text-base font-semibold text-center ${
                                            plan.name === 'Premium'
                                                ? 'bg-blue-600 text-white hover:bg-blue-700'
                                                : 'bg-black text-white hover:bg-gray-800'
                                        } disabled:opacity-50 relative`}
                                    >
                                        {loading ? (
                                            <>
                                                <span className="opacity-0">Processing...</span>
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                </div>
                                            </>
                                        ) : (
                                            plan.buttonText
                                        )}
                                    </button>
                                </div>

                                {/* Features list */}
                                <ul className="mt-8 space-y-4">
                                    {plan.features.map((feature) => {
                                        const isMainFeature = feature.includes('✨') || feature.includes('🎯') || feature.includes('🌟') || feature.includes('🤝');
                                        const isParagraph = feature.includes('.');
                                        
                                        return (
                                            <li key={feature} className={`flex items-start ${isParagraph ? 'mt-6' : ''}`}>
                                                {!isParagraph && (
                                                    <>
                                                        <svg
                                                            className={`flex-shrink-0 h-6 w-6 ${
                                                                plan.name === 'Premium' ? 'text-blue-500' : 'text-green-500'
                                                            }`}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                        <span className={`ml-3 ${isMainFeature ? 'text-gray-700 font-medium' : 'text-gray-500'}`}>
                                                            {feature}
                                                        </span>
                                                    </>
                                                )}
                                                {isParagraph && (
                                                    <p className="text-sm text-gray-500 mt-2">
                                                        {feature}
                                                    </p>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* FAQ Section */}
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12">
                <h2 className="text-3xl font-bold text-center mb-8">Frequently asked questions</h2>
                <div className="max-w-3xl mx-auto">
                    <dl className="space-y-8 divide-y divide-gray-200">
                        {faqs.map((faq, index) => (
                            <div key={index} className={index === 0 ? 'pt-0' : 'pt-8'}>
                                <dt className="text-lg font-semibold text-gray-900 mb-4">
                                    {faq.question}
                                </dt>
                                <dd className="text-base text-gray-500">
                                    {faq.answer}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
} 