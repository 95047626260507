import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { trackEvent } from '../lib/analytics';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [status, setStatus] = useState('pending');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { verifyEmail } = useAuth();

  useEffect(() => {
    if (token) {
      handleVerification();
    }
  }, [token]);

  const handleVerification = async () => {
    try {
      await verifyEmail(token);
      setStatus('success');
      trackEvent('email_verification_success');
      // Redirect to home after 2 seconds
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
      console.error('Verification error:', err);
      setStatus('error');
      setError(err.message || 'Verification failed');
      trackEvent('email_verification_error', { error: err.message });
    }
  };

  if (token) {
    return (
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 text-center">
          {status === 'pending' && (
            <>
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black mx-auto mb-4"></div>
              <h2 className="text-2xl font-bold mb-2">Verifying your email...</h2>
              <p className="text-gray-600">Please wait while we confirm your email address.</p>
            </>
          )}
          
          {status === 'success' && (
            <>
              <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-2">Email Verified!</h2>
              <p className="text-gray-600">Redirecting you to the home page...</p>
            </>
          )}
          
          {status === 'error' && (
            <>
              <div className="mx-auto w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-2">Verification Failed</h2>
              <p className="text-red-600 mb-4">{error}</p>
              <Link to="/" className="text-blue-600 hover:text-blue-800 font-medium">
                Return to Home
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }

  // Instructions view (no token)
  return (
    <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
      <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 text-center">
        <div className="mb-6">
          <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
            <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
            </svg>
          </div>
        </div>
        
        <h2 className="text-3xl font-bold mb-4">Check Your Email</h2>
        <div className="space-y-4 text-gray-600">
          <p className="text-lg">
            We've sent you a verification link to complete your registration.
          </p>
          <p>
            Click the link in the email to verify your account and start using Check-In Questions.
          </p>
          
          <div className="bg-gray-50 p-4 rounded-lg mt-6">
            <h3 className="font-medium text-gray-900 mb-2">Didn't receive the email?</h3>
            <ul className="text-sm space-y-2">
              <li>• Check your spam folder</li>
              <li>• Make sure you entered the correct email address</li>
              <li>• Allow a few minutes for the email to arrive</li>
            </ul>
          </div>
          
          <div className="mt-8">
            <Link to="/" className="text-blue-600 hover:text-blue-800 font-medium">
              Return to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 