import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getOpenAIClient, generateQuestion } from '../lib/openai';
import { checkSimilarity } from '../lib/embeddings';

const MAX_FREE_GENERATIONS = 5;
const MAX_RETRIES = 3;

// Add testing logs
const logQuestionAnalysis = (question, similarityAnalysis, attempt) => {
  console.group(`Question Generation - Attempt ${attempt}`);
  console.log('Generated Question:', question);
  if (similarityAnalysis) {
    console.log('Similarity Score:', similarityAnalysis.similarityScore);
    console.log('Similar To:', similarityAnalysis.mostSimilarTo);
    console.log('Analysis:', similarityAnalysis.reason);
  }
  console.groupEnd();
};

export function useQuestionGenerator() {
  const { supabase, user } = useAuth();

  // Memoized initial generations left
  const initialGenerationsLeft = useMemo(() => {
    const used = localStorage.getItem('generatedQuestions') || '0';
    console.log('Initial free generations left:', MAX_FREE_GENERATIONS - parseInt(used, 10));
    return Math.max(MAX_FREE_GENERATIONS - parseInt(used, 10), 0);
  }, []);

  // State
  const [generationsLeft, setGenerationsLeft] = useState(initialGenerationsLeft);
  const [selectedType, setSelectedType] = useState('check-in');
  const [selectedMood, setSelectedMood] = useState('curious');
  const [selectedComplexity, setSelectedComplexity] = useState('simple');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedQuestion, setGeneratedQuestion] = useState(null);
  const [previousQuestions, setPreviousQuestions] = useState([]);

  // Memoized handlers
  const handleTypeChange = useCallback((type) => {
    console.log('Type changed:', type);
    setSelectedType(type);
    setGeneratedQuestion(null);
  }, []);

  const handleMoodChange = useCallback((mood) => {
    console.log('Mood changed:', mood);
    setSelectedMood(mood);
    setGeneratedQuestion(null);
  }, []);

  const handleComplexityChange = useCallback((complexity) => {
    console.log('Complexity changed:', complexity);
    setSelectedComplexity(complexity);
    setGeneratedQuestion(null);
  }, []);

  // Track generated question in Supabase
  const trackGeneratedQuestion = useCallback(
    async (question) => {
      if (!user || !question) {
        console.log('Skipping question tracking - no user or question');
        return null;
      }

      console.log('Tracking generated question:', {
        type: selectedType,
        mood: selectedMood,
        complexity: selectedComplexity,
        userId: user.id
      });

      try {
        const questionData = {
          user_id: user.id,
          type: selectedType,
          mood: selectedMood,
          complexity: selectedComplexity,
          created_at: new Date().toISOString(),
          question: selectedType === 'energizer' ? JSON.stringify(question) : question
        };

        const { data, error } = await supabase
          .from('generated_questions')
          .insert([questionData])
          .select()
          .single();

        if (error) throw error;
        console.log('Question tracked successfully:', data);
        return data;
      } catch (error) {
        console.error('Failed to track question:', error);
        setError('Failed to save the generated question. Please try again.');
        return null;
      }
    },
    [supabase, user, selectedType, selectedMood, selectedComplexity]
  );

  // Fetch previous questions
  const fetchPreviousQuestions = useCallback(async () => {
    if (!user) {
      console.log('Skipping previous questions fetch - no user');
      return;
    }

    try {
      const { data: questionsData, error } = await supabase
        .from('generated_questions')
        .select('id, question, type, mood, complexity, created_at, votes_questions(vote_type, user_id)')
        .eq('type', selectedType)
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (questionsData) {
        const formattedQuestions = questionsData.map(q => ({
          id: q.id,
          content: q.question,
          type: q.type,
          mood: q.mood,
          complexity: q.complexity,
          voteCount: (q.votes_questions || [])
            .reduce((acc, v) => acc + (v.vote_type === 'helpful' ? 1 : -1), 0),
          userVote: (q.votes_questions || [])
            .find(v => v.user_id === user.id)?.vote_type || null,
        }));

        setPreviousQuestions(formattedQuestions.sort((a, b) => b.voteCount - a.voteCount));
      }
    } catch (error) {
      console.error('Failed to fetch previous questions:', error);
      setError('Failed to fetch previous questions.');
    }
  }, [supabase, user, selectedType]);

  // Fetch previous questions on mount and when selectedType changes
  useEffect(() => {
    if (user) {
      console.log('Initiating previous questions fetch for user:', user.id);
      fetchPreviousQuestions();
    }
  }, [user, selectedType, fetchPreviousQuestions]);

  // Generate question with similarity checking
  const generateQuestionWithChecks = useCallback(async () => {
    console.group('Starting New Question Generation');
    console.log('Configuration:', {
      type: selectedType,
      mood: selectedMood,
      complexity: selectedComplexity,
      previousQuestionsCount: previousQuestions.length
    });

    setError(null);
    setIsLoading(true);

    try {
      if (!user && generationsLeft <= 0) {
        throw new Error('You have reached the limit for free generations. Please sign in to continue.');
      }

      let finalQuestion = null;
      let attempts = 0;

      while (!finalQuestion && attempts < MAX_RETRIES) {
        attempts++;
        console.log(`\nAttempt ${attempts}/${MAX_RETRIES}`);

        const generatedContent = await generateQuestion({
          selectedType,
          selectedMood,
          selectedComplexity,
          previousQuestions: previousQuestions.slice(-10),
        });

        // Check similarity
        const similarityResult = await checkSimilarity(
          selectedType === 'energizer'
            ? `${generatedContent.title} ${generatedContent.description}`
            : generatedContent,
          previousQuestions
        );

        logQuestionAnalysis(
          selectedType === 'energizer'
            ? `${generatedContent.title} ${generatedContent.description}`
            : generatedContent,
          similarityResult,
          attempts
        );

        if (!similarityResult.isTooSimilar) {
          console.log('✅ Question accepted - Passed similarity check');
          finalQuestion = generatedContent;
        } else {
          console.log('❌ Question rejected - Too similar');
          continue;
        }
      }

      if (!finalQuestion) {
        throw new Error('Unable to generate a sufficiently unique question after multiple attempts. Please try again.');
      }

      // Track the question first to get the ID
      const trackedQuestion = await trackGeneratedQuestion(
        selectedType === 'energizer' ? JSON.stringify(finalQuestion) : finalQuestion
      );

      // Set the generated question with the ID from tracking
      setGeneratedQuestion(trackedQuestion ? {
        id: trackedQuestion.id,
        ...(selectedType === 'energizer' ? finalQuestion : { content: finalQuestion }),
        type: selectedType,
        mood: selectedMood,
        complexity: selectedComplexity
      } : {
        ...(selectedType === 'energizer' ? finalQuestion : { content: finalQuestion }),
        type: selectedType,
        mood: selectedMood,
        complexity: selectedComplexity
      });

      if (!user) {
        const used = parseInt(localStorage.getItem('generatedQuestions') || '0', 10);
        localStorage.setItem('generatedQuestions', (used + 1).toString());
        setGenerationsLeft(Math.max(MAX_FREE_GENERATIONS - (used + 1), 0));
      }

      console.log('✅ Question generation completed successfully');
    } catch (err) {
      console.error('❌ Error generating question:', err);
      setError(err.message || 'Failed to generate question. Please try again.');
      setGeneratedQuestion(null);
    } finally {
      setIsLoading(false);
      console.groupEnd();
    }
  }, [user, generationsLeft, selectedType, selectedMood, selectedComplexity, previousQuestions, trackGeneratedQuestion]);

  return {
    generationsLeft,
    selectedType,
    selectedMood,
    selectedComplexity,
    isLoading,
    error,
    generatedQuestion,
    previousQuestions,
    handleTypeChange,
    handleMoodChange,
    handleComplexityChange,
    generateQuestion: generateQuestionWithChecks,
    fetchPreviousQuestions,
  };
}

// Helper function to format energizer response
function formatEnergizer(parsedEnergizer) {
  return `
<div class="text-left">
  <h2 class="text-4xl font-bold mb-4">${parsedEnergizer.emoji} ${parsedEnergizer.title}</h2>
  <p class="text-xl mb-8">${parsedEnergizer.description}</p>
  <div class="mb-8 bg-gray-100 p-6 rounded-lg">
    <p class="text-xl mb-2">⏱️ <strong>Time:</strong> ${parsedEnergizer.timeFrame}</p>
    <p class="text-xl mb-2">👥 <strong>Group Size:</strong> ${parsedEnergizer.groupSize}</p>
    <p class="text-xl mb-2">🎯 <strong>Purpose:</strong> ${parsedEnergizer.purpose}</p>
  </div>
  <div class="mb-8">
    <h3 class="text-2xl font-bold mb-4">Steps</h3>
    <ol class="list-decimal list-outside pl-5 space-y-3 text-xl">
      ${parsedEnergizer.steps.map(step => `<li>${step.replace(/^Step \d+:\s*/, '')}</li>`).join('')}
    </ol>
  </div>
  <div>
    <h3 class="text-2xl font-bold mb-4">Facilitator Notes</h3>
    <ul class="list-disc list-outside pl-5 space-y-3 text-xl">
      ${parsedEnergizer.facilitatorNotes.map(note => `<li>${note.replace(/^Note \d+:\s*/, '')}</li>`).join('')}
    </ul>
  </div>
</div>`;
} 